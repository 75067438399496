import React from "react";
import styles from "./styles.module.css";
const VideoPlayer = ({
  video,
  id,
  rId,
  theme,
  style = null,
  height = null,
  position = null,
  paddingTop = null,
  marginTop = null,
  vclass = null,
  clickFunction = null,
}) => {
  const videoHandler = (e) => {
    // console.log({ aa: e.target.getAttribute("data-vid") });
    if (clickFunction) {
      if (e.target.getAttribute("data-vid")) {
        let video = document.getElementById(e.target.getAttribute("data-vid"));
        document.querySelectorAll(".testVideo1").forEach((vid) => {
          let a = vid.closest(".react-multi-carousel-item--active");
          let b = vid.getAttribute("data-rid");
          if (a) {
            if (vid.paused) {
              // console.log("a1", a);
              vid.play();
              let c = vid.previousSibling.id;
              if (c == "playBtn-mob-" + b) {
                if (
                  !vid.paused &&
                  vid.previousElementSibling.classList.contains(c)
                ) {
                  vid.previousElementSibling.style.display = "none";
                }
              }
            } else {
              vid.pause();
              let c = vid.previousSibling.id;
              if (
                vid.paused &&
                vid.previousElementSibling.classList.contains(c)
              ) {
                vid.previousElementSibling.style.display = "block";
              }
            }
          }
        });
      }
    } else {
      if (e.target.getAttribute("data-vid")) {
        let video = document.getElementById(e.target.getAttribute("data-vid"));
        if (video.paused) {
          video
            .play()
            .then((Response) => {
              console.log({ Response });
            })
            .catch((err) => {
              console.log(err);
            });
          document.getElementById(
            e.target.getAttribute("data-id")
          ).style.display = "none";
        } else {
          let pausedStatus = video.pause();
          // console.log({ pausedStatus });
          document.getElementById(
            e.target.getAttribute("data-id")
          ).style.display = "block";
        }
      }
    }
  };
  return (
    <div className={styles.holder}>
      <div
        className={`${styles.playerHolder} ${"playBtn-" + id} player`}
        id={`playBtn-${id}`}
        data-id={`playBtn-${id}`}
        data-vid={`video-${id}`}
        onClick={videoHandler}
        style={style ? style : { backgroundColor: theme }}
      ></div>
      <video
        className={`${
          vclass ? styles.videoEmbbed1 : styles.videoEmbbed
        } testVideo1`}
        style={
          (position && { position: "unset", height: height },
          paddingTop && { paddingTop: paddingTop },
          marginTop && { marginTop: marginTop })
        }
        poster={video.poster}
        frameBorder="0"
        data-id={`playBtn-${id}`}
        data-vid={`video-${id}`}
        data-rid={`${rId ? rId : ""}`}
        id={`video-${id}`}
        onClick={videoHandler}
        allowFullScreen=""
        mozallowfullscreen=""
        webkitallowfullscreen=""
        oallowfullscreen=""
        msallowfullscreen=""
        src={video.src}
      >
        <track src="" kind="captions" />
      </video>
    </div>
  );
};
export default VideoPlayer;
